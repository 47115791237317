import { ColaboradorModel } from '@/store/catalogos/colaborador.model';
import { AppState } from '@/store/state';
import { UiState } from '@/store/ui/state';
import { Util } from '@/utils/util';
import { AfterViewInit, Component, HostBinding, OnInit, Renderer2,  } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { ApiService } from '@services/api.service';
import { AppService } from '@services/app.service';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';

const BASE_CLASSES = 'main-sidebar elevation-4';
@Component({
    selector: 'app-menu-sidebar',
    templateUrl: './menu-sidebar.component.html',
    styleUrls: ['./menu-sidebar.component.scss']
})
export class MenuSidebarComponent implements OnInit, AfterViewInit {
    @HostBinding('class') classes: string = BASE_CLASSES;
    public ui: Observable<UiState>;
    public user;
    public menu;
    sesion:ColaboradorModel;
    autoOcultarMenu:boolean;

    constructor(
         public appService: AppService, private store: Store<AppState>,
         private apiService: ApiService, private toastr: ToastrService
        ,public dialog: MatDialog, private router: Router
        ,private util: Util,private renderer: Renderer2) {
    }

    ngOnInit() {
        this.ui = this.store.select('ui');
        this.ui.subscribe((state: UiState) => {
            this.classes = `${BASE_CLASSES} ${state.sidebarSkin}`;
        });
        this.user = this.appService.user;
        this.loadMenu();
        this.autoOcultarMenu = JSON.parse(localStorage.getItem("autoOcultarMenu"));

        this.sesion = this.util.getSesion();
        this.menu =MENU_1;
    }

    ngAfterViewInit(){
        this.sesion = this.util.getSesion();
    }

    async loadMenu() {
        this.apiService.menu().subscribe(response => {
            this.menu = response;
            localStorage.setItem("menu", response.toString());
        }, error => {
            
        });
    }

    ocultarMenu(){
        this.util.ocultarMenu(this.renderer);
    }

    changeOcultarMenu(){
        localStorage.setItem("autoOcultarMenu",this.autoOcultarMenu+"");
        this.util.autoOcultarMenu(this.renderer);
    }

}

export const MENU_1 = [
    {
        name: 'Information',
        iconClasses: 'far fa-folder',
        path: ['students/information']
    },    {
        name: 'Especialidad',
        iconClasses: 'far fa-folder',
        path: ['students/especialidad']
    }
];
