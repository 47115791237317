import { DialogSelectorModel } from '@/store/util/DialogSelectorData.model';
import { Util } from '@/utils/util';
import { Component,Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Table } from 'primeng/table';

@Component({
  selector: 'app-dialog-selector',
  templateUrl: './dialog-selector.component.html',
  styleUrls: ['./dialog-selector.component.scss']
})
export class DialogSelectorComponent {

  selectAll:boolean = false;
  filtro="";

  constructor(
    public dialog: MatDialog,
    private util:Util,
    public dialogRef: MatDialogRef<DialogSelectorComponent>,
    @Inject(MAT_DIALOG_DATA) public data:DialogSelectorModel){
      if(this.data.multiple==undefined){
        this.data.multiple=false;
      }
  }

  filtarFilas(row):boolean{
    let show = false;
    for(let i = 0; i < this.data.columns.length ; i++){
      let column:string = this.data.columns[i].key; 
      let field = row[column];
      if(this.util.nvl(field+"","").toLowerCase().includes(this.util.nvl(this.filtro,"").toLowerCase())){
        show = true;
        break;
      }
    }
    return show;
  }

  async selectRow(row){
    if(!this.data.multiple){
      this.data.dataSource.forEach(row =>{
        row.selected=false;
      });
    }
    row.selected = !row.selected;
  }

  selectAllRows(){
    this.data.dataSource.forEach(row =>{
      row.selected=this.selectAll;
    });
  }
  
  getRowsSelected(){
    let r:any[] = [];
    this.data.dataSource.forEach(row =>{
      if(row.selected){
        r.push(row);
      }
    });
    return r;
  }

  public selectAndAcept(row){
    this.selectRow(row);
    this.acept();
  }

  public acept(){
    this.dialogRef.close({data:{acept:true, rows:this.getRowsSelected()}});
  }

  public cancel(){
    this.dialogRef.close();
  }

  filterTable(event: Event) {
    const filtro = (event.target as HTMLInputElement).value;
    //this.dataSource.filter = filtro.trim().toLowerCase();

  }

}
